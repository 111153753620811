export default [
  {
    value: 'us-east-2',
    text: 'US East (Ohio)',
  },
  {
    value: 'us-east-1',
    text: 'US East (N. Virginia)',
  },
  {
    value: 'us-west-1',
    text: 'US West (N. California)',
  },
  {
    value: 'us-west-2',
    text: 'US West (Oregon)',
  },
  {
    value: 'ap-northeast-1',
    text: 'Asia Pacific (Tokyo)',
  },
  {
    value: 'ap-northeast-2',
    text: 'Asia Pacific (Seoul)',
  },
  {
    value: 'ap-northeast-3',
    text: 'Asia Pacific (Osaka-Local)',
  },
  {
    value: 'ap-south-1',
    text: 'Asia Pacific (Mumbai)',
  },
  {
    value: 'ap-southeast-1',
    text: 'Asia Pacific (Singapore)',
  },
  {
    value: 'ap-southeast-2',
    text: 'Asia Pacific (Sydney)',
  },
  {
    value: 'ca-central-1',
    text: 'Canada (Central)',
  },
  {
    value: 'cn-north-1',
    text: 'China (Beijing)',
  },
  {
    value: 'cn-northwest-1',
    text: 'China (Ningxia)',
  },
  {
    value: 'eu-central-1',
    text: 'EU (Frankfurt)',
  },
  {
    value: 'eu-west-1',
    text: 'EU (Ireland)',
  },
  {
    value: 'eu-west-2',
    text: 'EU (London)',
  },
  {
    value: 'eu-west-3',
    text: 'EU (Paris)',
  },
  {
    value: 'sa-east-1',
    text: 'South America (São Paulo)',
  },
  {
    value: 'us-gov-west-1',
    text: 'AWS GovCloud (US)',
  },
]
