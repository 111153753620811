import { render, staticRenderFns } from "./project-configuration-logs.vue?vue&type=template&id=a0c7cc1c"
import script from "./project-configuration-logs.vue?vue&type=script&lang=js"
export * from "./project-configuration-logs.vue?vue&type=script&lang=js"
import style0 from "./project-configuration-logs.vue?vue&type=style&index=0&id=a0c7cc1c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAvatar,VCol,VIcon,VListItemAvatar,VListItemContent,VListItemTitle,VSelect})
