import { aws, google, azurerm, elasticsearch, swift, gitlab } from '@/assets/icons'

const supported = true

export const INFRAVIEW_EXTERNAL_BACKENDS = _.mapValues({
  AWSStorage: {
    supported,
    configurationFields: {
      region: 'externalBackendsConfig.fieldDescription.awsRegion',
      bucket: 'externalBackendsConfig.fieldDescription.awsBucket',
      key: 'externalBackendsConfig.fieldDescription.awsKey',
      endpoint: 'externalBackendsConfig.fieldDescription.awsEndpoint',
      s3ForcePathStyle: 'externalBackendsConfig.fieldDescription.awsS3ForcePathStyle',
      skipVerifySSL: 'externalBackendsConfig.fieldDescription.awsSkipVerifySSL',
    },
    icon: aws.logo,
    text: 'AWS / S3 compatible',
    type: 'aws',
  },
  AzureStorage: {
    supported,
    configurationFields: {
      container: 'externalBackendsConfig.fieldDescription.azureContainer',
      blob: 'externalBackendsConfig.fieldDescription.azureBlob',
    },
    icon: azurerm.logo,
    text: 'Azure',
    type: 'azure_storage',
  },
  GCPStorage: {
    supported,
    configurationFields: {
      bucket: 'externalBackendsConfig.fieldDescription.gcpBucket',
      object: 'externalBackendsConfig.fieldDescription.gcpObject',
    },
    icon: google.logo,
    text: 'GCP',
    type: 'gcp',
  },
  GitLabHTTPStorage: {
    supported,
    configurationFields: {
      url: 'externalBackendsConfig.fieldDescription.gitlabHttpUrl',
    },
    icon: gitlab.logo,
    text: 'GitLab (HTTP)',
    type: 'basic_auth',
  },
  HTTPStorage: {
    supported,
    configurationFields: {
      url: 'externalBackendsConfig.fieldDescription.httpUrl',
    },
    icon: 'language',
    text: 'HTTP',
    type: 'basic_auth',
  },
  SwiftStorage: {
    supported,
    configurationFields: {
      region: 'externalBackendsConfig.fieldDescription.swiftRegion',
      container: 'externalBackendsConfig.fieldDescription.swiftContainer',
      object: 'externalBackendsConfig.fieldDescription.swiftObject',
      skipVerifySSL: 'externalBackendsConfig.fieldDescription.swiftSkipVerifySSL',
    },
    icon: swift.logo,
    text: 'SWIFT',
    type: 'swift',
  },
}, (eb) => ({ ...eb, purpose: 'remote_tfstate' }))

export const LOGS_EXTERNAL_BACKENDS = _.mapValues({
  AWSCloudWatchLogs: {
    supported,
    configurationFields: {
      region: 'externalBackendsConfig.fieldDescription.awsCloudWatchRegion',
    },
    icon: aws.logo,
    text: 'Amazon Cloudwatch',
    type: 'aws-cloudwatch',
  },
  ElasticsearchLogs: {
    supported,
    configurationFields: {},
    icon: elasticsearch.logo,
    text: 'Elasticsearch',
    type: 'elasticsearch',
  },
}, (eb) => ({ ...eb, purpose: 'logs' }))

export const EVENTS_EXTERNAL_BACKENDS = _.mapValues({
  AWSCloudWatchLogs: {
    supported,
    configurationFields: {},
    icon: aws.logo,
    text: 'Amazon Cloudwatch',
    type: 'aws-cloudwatch',
  },
}, (eb) => ({ ...eb, purpose: 'events' }))

export const EXTERNAL_BACKENDS = {
  remote_tfstate: INFRAVIEW_EXTERNAL_BACKENDS,
  logs: LOGS_EXTERNAL_BACKENDS,
  events: EVENTS_EXTERNAL_BACKENDS,
}
