<template>
  <section class="two-column-section">
    <header class="two-column-section__header">
      <h2 class="h5">
        {{ $t('untranslated.logs') }}
      </h2>
      <p class="subtitle mb-2">
        {{ $t('logs.subtitle') }}
      </p>
      <a
        :href="$docLinks.project.logs.index"
        target="_blank"
        rel="noopener noreferrer"
        class="cy-link">
        {{ $t('logs.linkText') }}
        <v-icon
          small
          color="#A8A7A7">
          open_in_new
        </v-icon>
      </a>
    </header>

    <div class="two-column-section__content">
      <div
        v-if="loading"
        class="d-flex flex-column sk-block sk-w-100 pa-4 space-y-3">
        <div class="d-flex mb-6 space-x-0">
          <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
          <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
          <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
        </div>
        <div class="sk-block sk-dark sk-h-5 sk-full-width"/>
        <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
      </div>

      <v-col
        v-else
        tag="section"
        class="section grow"
        cols="12">
        <div class="form">
          <CyAlert
            class="my-2"
            theme="error"
            :errors="errors.externalBackend"/>
          <h2 class="mb-2 font-size-base font-weight-normal">
            {{ $t('logProvider') }}
          </h2>
          <CyBtnToggle
            v-model="form.logProviderIndex"
            :items="logProviderItems"
            class="mb-6"/>

          <template v-if="isAddingLogProviderFor('AWSCloudWatchLogs')">
            <v-select
              v-model="$v.form.logProvider.credentialCanonical.$model"
              v-has-rights-to="'ListCredentials'"
              class="input-md required-field"
              :menu-props="{ offsetY: true, maxHeight: 308 }"
              :disabled="!canGetCredentials || !canUpdateProject"
              :readonly="!canGetCredentials || !canUpdateProject"
              :items="credentials"
              :label="$t('Credential')"
              item-value="canonical"
              item-text="name">
              <template #selection="{ item }">
                <v-avatar
                  :key="item.name"
                  class="ml-2 mr-3"
                  size="24">
                  <CyIconCredential :type="item.type"/>
                </v-avatar>
                <div class="black--text">
                  {{ item.name }}
                </div>
              </template>
              <template #item="{ item }">
                <v-list-item-avatar class="mr-3">
                  <CyIconCredential :type="item.type"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-select
              v-model="$v.form.logProvider.configuration.region.$model"
              class="input-md required-field"
              :menu-props="{ offsetY: true }"
              :disabled="!canUpdateProject"
              :readonly="!canUpdateProject"
              :items="$static.AWS_CLOUD_WATCH_REGIONS"
              :label="$t('region')"/>

            <div
              v-if="canUpdateProject"
              class="form__actions">
              <CyButton
                :loading="saving"
                :disabled="!canSave"
                variant="primary"
                theme="secondary"
                icon="check"
                @click="save">
                {{ $t('forms.btnSave') }}
              </CyButton>
            </div>
          </template>

          <template v-if="isAddingLogProviderFor('ElasticsearchLogs')">
            <CyButton
              class="mx-0"
              :disabled="!canGetCredentials || !canUpdateProject"
              @click="goToElasticSearchConfigPage">
              {{ $t('configureLogSources') }}
            </CyButton>
          </template>
        </div>
      </v-col>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle.vue'
import AWS_CLOUD_WATCH_REGIONS from '@/utils/config/aws-cloudwatch-regions'
import { LOGS_EXTERNAL_BACKENDS } from '@/utils/config/external-backends'
import { constructBreadcrumb } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyPageProjectConfigurationLogs',
  components: {
    CyBtnToggle,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectConfiguration'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return { title: this.$t('routes.projectConfiguration') }
  },
  validations () {
    return {
      form: {
        logProvider: {
          credentialCanonical: { required },
          configuration: this.logsValidations,
        },
      },
    }
  },
  data: () => ({
    loading: false,
    saving: false,
    form: {
      logProviderIndex: 0,
      logProvider: {
        id: null,
        credentialCanonical: null,
        configuration: {
          engine: null,
        },
      },
    },
  }),
  computed: {
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
    }),
    ...mapState('organization/project', {
      projectErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/project', [
      'logProvider',
      'projectCanonical',
    ]),
    $static: () => ({
      AWS_CLOUD_WATCH_REGIONS,
    }),
    errors () {
      const { externalBackend = [], project = [] } = this.projectErrors
      return { externalBackend, project }
    },
    canGetCredentials () {
      return _.some([
        this.$cycloid.permissions.canDisplay('GetCredential'),
        this.$cycloid.permissions.canDisplay('ListCredentials'),
      ])
    },
    canUpdateProject () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.projectCanonical)
    },
    canSave () {
      return !this.$v.form.logProvider.$invalid && this.$hasDataChanged('form')
    },
    hasLogsConfigured () {
      return !_.isEmpty(this.logProvider)
    },
    logProviderItems () {
      return _(LOGS_EXTERNAL_BACKENDS)
        .map(({ icon: image, text, type: key }, purpose) => ({ purpose, image, text, key, variant: 'secondary' }))
        .map((logProviderItem, idx) => ({ ...logProviderItem, theme: this.form.logProviderIndex === idx ? 'secondary' : 'grey' }))
        .value()
    },
    logsValidations () {
      return {
        AWSCloudWatchLogs: {
          region: { required },
        },
      }[this.form.logProvider.configuration.engine] || {}
    },
  },
  watch: {
    'form.logProviderIndex': {
      handler (index) {
        this.form.logProvider.configuration.engine = this.logProviderItems[index].purpose
      },
      immediate: true,
    },
    'form.logProvider.configuration.engine': {
      handler () {
        this.setConfiguration()
      },
      immediate: true,
    },
    '$route.name': {
      async handler (name) {
        if (name === 'projectConfigurationLogs' && this.canGetCredentials) {
          await this.FETCH_AVAILABLE({ keyPath: 'credentials', extraParams: ['aws'] })
        }
      },
      immediate: true,
    },
  },
  async mounted () {
    this.loading = true
    await this.GET_EXTERNAL_BACKENDS()
    this.setup()
    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'CREATE_EXTERNAL_BACKEND',
      'GET_EXTERNAL_BACKENDS',
      'UPDATE_EXTERNAL_BACKEND',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    setup () {
      if (this.hasLogsConfigured) this.setLogProvider()
      this.$setOriginalData('form')
    },
    setLogProvider () {
      const { id, credential_canonical: credentialCanonical, configuration } = this.logProvider
      this.form.logProvider = {
        id,
        credentialCanonical,
        configuration: _.cloneDeep(configuration),
      }
      this.form.logProviderIndex = _.findIndex(this.logProviderItems, ({ purpose }) => purpose === configuration.engine)
    },
    isAddingLogProviderFor (engine) {
      return engine === this.form.logProvider.configuration.engine
    },
    async save () {
      this.saving = true
      this.CLEAR_PROJ_ERRORS('externalBackend')

      const { configuration, configuration: { engine }, credentialCanonical, id = null } = this.form.logProvider
      const config = {
        purpose: LOGS_EXTERNAL_BACKENDS[engine].purpose,
        credential_canonical: credentialCanonical,
        project_canonical: this.projectCanonical,
        configuration: _.cloneDeep(configuration),
      }

      const success = this.hasLogsConfigured
        ? await this.UPDATE_EXTERNAL_BACKEND({ externalBackendId: id, config })
        : await this.CREATE_EXTERNAL_BACKEND(config)

      if (success) {
        this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.project.config.logProviderSaved') })
        await this.GET_EXTERNAL_BACKENDS()
        this.setup()
      }

      this.saving = false
    },
    setConfiguration () {
      const { region } = this.logProvider?.configuration || this.form.logProvider?.configuration || {}

      const configuration = {
        AWSCloudWatchLogs: {
          region,
          engine: 'AWSCloudWatchLogs',
        },
        ElasticsearchLogs: {
          engine: 'ElasticsearchLogs',
        },
      }

      this.form.logProvider.configuration = configuration[this.form.logProvider.configuration.engine]
    },
    goToElasticSearchConfigPage () {
      const location = this.hasLogsConfigured
        ? { name: 'projectConfigElasticSearch', params: { externalBackendId: this.logProvider.id } }
        : { name: 'newProjectConfigElasticSearch' }
      this.$router.push(location)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectConfiguration',

        configureLogSources: 'Configure log sources',
        logProvider: 'Log provider',
        logs: {
          title: 'Logs Configuration',
          subtitle: 'Configure a log provider to display logs in environment views. Enabling logs helps your teams monitor your application activity and can centralise application and infrastructure logs in one place.',
          linkText: 'Learn more about configuring logs',
        },
        region: 'Amazon CloudWatch Region',
      },
      es: {
        title: '@:routes.projectConfiguration',
        configureLogSources: 'Configurar fuentes de los logs',
        logProvider: 'Log proveedor',
        logs: {
          title: 'Configuración de los Logs',
          subtitle: 'Configure un proveedor de registros para mostrar registros en vistas del entorno. Habilitar registros ayuda a sus equipos a monitorear la actividad de sus aplicaciones y puede centralizar los logs de aplicaciones e infraestructura en un solo lugar.',
          linkText: 'Obtenga más información sobre la configuración de logs',
        },
        region: 'Amazon CloudWatch Región',
      },
      fr: {
        title: '@:routes.projectConfiguration',
        configureLogSources: 'Configurer les sources de logs',
        logProvider: 'Log fournisseur',
        logs: {
          title: 'Configuration pour les Logs',
          subtitle: `Configurez un fournisseur de journaux pour afficher les logs dans les vues d'environnement. L'activation des logs aide vos équipes à surveiller l'activité de vos applications et peut centraliser les logs d'application et d'infrastructure en un seul endroit.`,
          linkText: 'En savoir plus sur la configuration des logs',
        },
        region: 'Amazon CloudWatch Région',
      },
    },
  },
}
</script>

<style lang="scss">
.section {
  padding: 0 12px;

  & + & {
    @include breakpoint("s") {
      margin-top: 48px;
    }

    @include breakpoint("m") {
      margin-top: 48px;
    }
  }

  &__header {
    padding-bottom: 24px;
  }

  &__title {
    color: cy-get-color("primary");
  }

  .description {
    height: 3em;
    margin-bottom: 0;
    color: cy-get-color("grey", "dark-2");
  }

  .input-md {
    max-width: 320px;
  }
}
</style>
